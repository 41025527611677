import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import './app.scss'

// Components / Containers
import HomePage from './containers/HomePage/HomePage'
import ContactContainer from './containers/ContactContainer/ContactContainer'

const App = () => {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/">
            <HomePage />
            <ContactContainer />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default App
