import React, { useState, useEffect } from "react";
import { useMediaPredicate } from "react-media-hook";

// React Modal
import ReactModal from "react-modal";

// MUI
import { Typography, Grid, Button } from "@material-ui/core";

import "./styles.scss";

// images
import harmonyMobile from "../../../images/harmony-mobile.png";
import harmonyWeb from "../../../images/harmony-web.png";
import tnsWeb from "../../../images/tns-web.png";
import tnsMobile from "../../../images/tns-mobile.png";
import workMachinesWeb from "../../../images/work-machines-web.png";
import workMachinesMobile from "../../../images/work-machines-mobile.png";
import pressCustomerWeb from "../../../images/press-customer-web.png";
import pressCustomerMobile from "../../../images/press-customer-mobile.png";
import pressProviderWeb from "../../../images/press-provider-web.png";
import pressProviderMobile from "../../../images/press-provider-mobile.png";
import rhcConsultingWeb from "../../../images/rhc-consulting-web.jpg";
import rhcConsultingMobile from "../../../images/rhc-consulting-mobile.png";
import calculatorWeb from "../../../images/calc-web.png";
import calculatorMobile from "../../../images/calc-mobile.png";
import awesomeJobsWeb from "../../../images/awesome-jobs-web.png";
import awesomeJobsMobile from "../../../images/awesome-jobs-mobile.png";
import ecommerceWeb from "../../../images/ecommerce-web.png";
import ecommerceMobile from "../../../images/ecommerce-mobile.png";
import frqncyWeb from "../../../images/frqncy-web.png";
import frqncyMobile from "../../../images/frqncy-mobile.png";

import pressDriverMobile from "../../../images/press-driver-mobile.png";
import pressDriverMobile2 from "../../../images/press-driver-mobile-2.png";
import pressDriverMobile3 from "../../../images/press-driver-mobile-3.png";

// videos
import harmonyWebVideo from "../../../videos/harmony-web.mp4";
import providerWebVideo from "../../../videos/provider-web.mp4";
import customerWebVideo from "../../../videos/customer-web.mp4";
import tnsCreateVideo from "../../../videos/tns-create.mp4";
import workMachinesVideo from "../../../videos/work-machines.mp4";
import frqncyWebVideo from "../../../videos/frqncy-web.mp4";

const Project = (props) => {
  // destructure
  const { project } = props;
  const { title, summary, image, tags } = project;

  // media queries
  const smallerThan1450 = useMediaPredicate("(max-width: 1450px)");
  const biggerThan1300 = useMediaPredicate("(min-width: 1300px)");
  const biggerThan1200 = useMediaPredicate("(min-width: 1200px)");
  const biggerThan1050 = useMediaPredicate("(min-width: 1050px)");
  const biggerThan1000 = useMediaPredicate("(min-width: 1000px)");
  const biggerThan900 = useMediaPredicate("(min-width: 900px)");
  const biggerThan815 = useMediaPredicate("(min-width: 815px)");
  const biggerThan700 = useMediaPredicate("(min-width: 700px)");
  const biggerThan600 = useMediaPredicate("(min-width: 600px)");
  const biggerThan550 = useMediaPredicate("(min-width: 550px)");
  const biggerThan500 = useMediaPredicate("(min-width: 500px)");
  const biggerThan375 = useMediaPredicate("(min-width: 375px)");

  // media state
  const [video, setVideo] = useState(null);
  const [webImage, setWebImage] = useState(null);
  const [mobileImage, setMobileImage] = useState(null);
  const [mobileImage2, setMobileImage2] = useState(null);
  const [mobileImage3, setMobileImage3] = useState(null);

  // functional state
  const [modalOpen, setModalOpen] = useState(false);

  // Component Did Mount
  useEffect(() => {
    switch (image) {
      case "frqncy":
        setWebImage(frqncyWeb);
        setMobileImage(frqncyMobile);
        setVideo(frqncyWebVideo);
        break;
      case "harmony":
        setWebImage(harmonyWeb);
        setMobileImage(harmonyMobile);
        setVideo(harmonyWebVideo);
        break;
      case "tns":
        setWebImage(tnsWeb);
        setMobileImage(tnsMobile);
        setVideo(tnsCreateVideo);
        break;
      case "work-machines":
        setWebImage(workMachinesWeb);
        setMobileImage(workMachinesMobile);
        setVideo(workMachinesVideo);
        break;
      case "press-customer-app":
        setWebImage(pressCustomerWeb);
        setMobileImage(pressCustomerMobile);
        setVideo(customerWebVideo);
        break;
      case "press-provider-app":
        setWebImage(pressProviderWeb);
        setMobileImage(pressProviderMobile);
        setVideo(providerWebVideo);
        break;
      case "press-driver-app":
        setMobileImage(pressDriverMobile);
        setMobileImage2(pressDriverMobile2);
        setMobileImage3(pressDriverMobile3);
        break;
      case "rhc-consulting":
        setWebImage(rhcConsultingWeb);
        setMobileImage(rhcConsultingMobile);
        break;
      case "calculator":
        setWebImage(calculatorWeb);
        setMobileImage(calculatorMobile);
        break;
      case "awesome-jobs":
        setWebImage(awesomeJobsWeb);
        setMobileImage(awesomeJobsMobile);
        break;
      case "ecommerce":
        setWebImage(ecommerceWeb);
        setMobileImage(ecommerceMobile);
        break;
      default:
        // console.log('couldn\'t find an image')
        break;
    }
  }, [image]);

  // Open Modal
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  return (
    <div class="project-mobile-container">
      <ReactModal
        isOpen={modalOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModalOpen(false)}
        style={{
          overlay: {
            display: "flex",
            justifyContent: "center",
          },
          content: {
            right: "unset",
            bottom: "unset",
            overflow: "hidden",
            maxHeight: 839,
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <video
          autoPlay
          height={
            biggerThan1300
              ? "680px"
              : biggerThan1200
              ? "600px"
              : biggerThan1050
              ? "550px"
              : biggerThan1000
              ? "520px"
              : biggerThan900
              ? "465px"
              : biggerThan815
              ? "420px"
              : biggerThan700
              ? "350px"
              : biggerThan600
              ? "300px"
              : biggerThan550
              ? "270px"
              : biggerThan500
              ? "240px"
              : biggerThan375
              ? "180px"
              : "200px"
          }
        >
          <source controls src={video} playsinline="true" preload="auto" />
        </video>
      </ReactModal>

      <Grid
        container
        direction="row"
        alignContent="center"
        spacing={1}
        className="grid"
      >
        <Grid item xs={12} className="grid" style={{ marginBottom: "20px" }}>
          {project.image && project.title != "Press Driver" && (
            <div className="images-container">
              <img
                src={webImage}
                width="100%"
                height="auto"
                alt=""
                className="image"
                style={{ borderRadius: "5px" }}
              />
              <img
                src={mobileImage}
                width="20%"
                height="auto"
                alt=""
                className="image"
                style={{
                  position: "absolute",
                  bottom: -10,
                  left: -15,
                  borderRadius: "5px",
                }}
              />
            </div>
          )}
          {project.image && project.title == "Press Driver" && (
            <div className="native-images-container">
              <img src={pressDriverMobile} alt="" className="image" />
              <img src={pressDriverMobile2} alt="" className="image" />
              <img src={pressDriverMobile3} alt="" className="image" />
            </div>
          )}
        </Grid>
        {biggerThan1000 && smallerThan1450 ? (
          <Grid item xs={12} style={{ padding: "0 100px" }}>
            <div>
              <Typography
                variant="h4"
                className="text"
                style={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                {title}
              </Typography>

              {/* tags */}
              <div
                className="tags-container"
                style={tags ? { display: "flex" } : { display: "none" }}
              >
                {tags &&
                  tags.map((tag) => {
                    return <span className="tag-text">{tag}</span>;
                  })}
              </div>

              <Typography
                variant="body1"
                className="text"
                style={{ textAlign: "justify" }}
              >
                {summary}
              </Typography>
              <div className="buttons">
                {project.website && (
                  <Button className="button" >
                    <a target="_blank" href={project.website}>{project.company}</a>
                  </Button>
                )}
                {project.video && (
                  <Button onClick={handleModalOpen} className="button">
                    <span>View Demo</span>
                  </Button>
                )}
              </div>
            </div>
          </Grid>
        ) : biggerThan700 ? (
          <Grid item xs={12}>
            <div>
              <Typography
                variant="h4"
                className="text"
                style={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                {title}
              </Typography>

              {/* tags */}
              <div
                className="tags-container"
                style={tags ? { display: "flex" } : { display: "none" }}
              >
                {tags &&
                  tags.map((tag) => {
                    return <span className="tag-text">{tag}</span>;
                  })}
              </div>

              <Typography
                variant="body1"
                className="text"
                style={{ textAlign: "justify" }}
              >
                {summary}
              </Typography>
              <div className="buttons">
                {project.website && (
                  <button className="button">
                    <a target="_blank" href={project.website}>{project.company}</a>
                  </button>
                )}
                {project.video && (
                  <button onClick={handleModalOpen} className="button">
                    <span>View Demo</span>
                  </button>
                )}
              </div>
            </div>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <div>
              <Typography
                variant="h5"
                className="text-shrink"
                style={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                {title}
              </Typography>

              {/* tags */}
              <div
                className="tags-container"
                style={tags ? { display: "flex" } : { display: "none" }}
              >
                {tags &&
                  tags.map((tag) => {
                    return <span className="tag-text">{tag}</span>;
                  })}
              </div>

              <Typography
                variant="body2"
                className="text-shrink"
                style={{ textAlign: "justify" }}
              >
                {summary}
              </Typography>
              <div className="buttons">
                {project.website && (
                  <button className="button">
                    <a target="_blank" href={project.website}>{project.company}</a>
                  </button>
                )}
                {project.video && (
                  <button onClick={handleModalOpen} className="button">
                    <span>View Demo</span>
                  </button>
                )}
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Project;
