import React, { useEffect } from "react";
import { useTags, useTagsDispatch } from "../../../contexts/TagsContext.js";

// Styles
import "./styles.scss";

const ListFilter = () => {
  const dispatch = useTagsDispatch();
  const tagsState = useTags();

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset === 0) {
        dispatch({
          type: "reset_tags",
        });
      }
    };
  }, []);

  return (
    <div className="sticky-wrapper">
      <div className="btn-wrapper">
        {/* Tags */}
        {tagsState.tagsData.map((tag, index) => {
          return (
            <button
              onClick={() => {
                window.scroll({
                  behavior: "smooth",
                  top:
                    document
                      .querySelector("#work-container")
                      .getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    100,
                });
                dispatch({
                  type: "update_tags",
                  payload: tag,
                });
              }}
              className={`tag-btn 
              ${tag.name.toLowerCase()}`}
              key={index}
            >
              {tag.name}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ListFilter;
