import React, { useState, useEffect } from "react";
import { useMediaPredicate } from "react-media-hook";
import { useTags } from "../../contexts/TagsContext.js";

// Components
import Project from "../../components/Custom/Project/Project";
import ProjectMobile from "../../components/Custom/Project/ProjectMobile";

const WorkContainer = () => {
  const tagsState = useTags();
  const biggerThan1700 = useMediaPredicate("(min-width: 1700px)");

  return (
    <div id="work-container">
      {tagsState.filteredWorkData.map((project, index) => {
        return !biggerThan1700 ? (
          <ProjectMobile project={project} key={index} index={index} />
        ) : (
          <Project project={project} key={index} index={index} />
        );
      })}
    </div>
  );
};

export default WorkContainer;
