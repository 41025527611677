import React, { createContext, useContext, useReducer } from "react";

// Data import
import work from "../data/work.js";
import tags from "../data/tags.js";

// Initial State
const tagsData = [...tags];

const initialTagsState = {
  workData: work,
  tagsData: tagsData,
  filteredWorkData: [...work],
  relatedTags: null,
  activeTags: [],
};

// Initialize Context
const TagsContext = createContext(null);
// Initialize Dispatch Context
const TagsDispatchContext = createContext(null);

// Create Context
export function TagsProvider({ children }) {
  const [tagsState, dispatch] = useReducer(tagsReducer, initialTagsState);

  return (
    <TagsContext.Provider value={tagsState}>
      <TagsDispatchContext.Provider value={dispatch}>
        {children}
      </TagsDispatchContext.Provider>
    </TagsContext.Provider>
  );
}

// Read Context
export function useTags() {
  return useContext(TagsContext);
}

// Update Context
export function useTagsDispatch() {
  return useContext(TagsDispatchContext);
}

// Create Reducer
function tagsReducer(tagsState, action) {
  switch (action.type) {
    case "update_tags": {
      // if activeTag is empty, set activeTag to payload
      if (tagsState.activeTags === null || tagsState.activeTags.length === 0) {
        // set active to true on payload
        action.payload.active = true;

        const mappedRelatedTags = action.payload.relatedTags.map((tag) => {
          return tagsState.tagsData.find((tagObj) => tag === tagObj.name);
        });

        return {
          ...tagsState,
          filteredWorkData: tagsState.workData.filter((project) =>
            project.tags.includes(action.payload.name)
          ),
          tagsData: tagsState.tagsData,
          relatedTags: mappedRelatedTags,
          activeTags: [action.payload],
        };
      } else {
        action.payload.active = !action.payload.active;

        // NEW TAGS DATA
        const newTagsData = tagsState.tagsData.map((tag) => {
          if (tag.name === action.payload.name) {
            return {
              ...tag,
              active: action.payload.active,
            };
          } else return tag;
        });

        // NEW ACTIVE TAGS
        let newActiveTags = [...tagsState.activeTags];
        let indexOfActionPayload = newActiveTags.findIndex(
          (tag) => tag.name === action.payload.name
        );
        if (!action.payload.active) {
          newActiveTags.splice(indexOfActionPayload, 1);
        } else {
          newActiveTags.push(action.payload);
        }

        // NEW RELATED TAGS
        const newRelatedTags = newTagsData.filter((tag) => {
          // counting the number of active tags that are within a tag's related tags
          let count = 0;
          newActiveTags.forEach((activeTag) => {
            // if a tags related tags includes an active tag
            if (tag.relatedTags.includes(activeTag.name)) {
              count++;
            }
          });

          if (count == newActiveTags.length) {
            return true;
          } else {
            return false;
          }
        });

        // NEW FILTERED WORK DATA
        const newFilteredWorkData = tagsState.workData.filter((project) => {
          let count = 0;

          newActiveTags.forEach((tag) => {
            if (project.tags.includes(tag.name)) {
              count++;
            }
          });

          if (count == newActiveTags.length) {
            return true;
          } else {
            return false;
          }
        });

        return {
          ...tagsState,
          filteredWorkData: newFilteredWorkData,
          tagsData: newTagsData,
          relatedTags: newRelatedTags,
          activeTags: newActiveTags,
        };
      }
    }
    case "reset_tags": {
      return {
        ...tagsState,
        filteredWorkData: tagsState.workData,
        tagsData: tagsState.tagsData.map((tag) => {
          if (tag.active) {
            return {
              ...tag,
              active: false,
            };
          } else return tag;
        }),
        relatedTags: null,
        activeTags: [],
      };
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}
