const tags = [
  {
    name: "React",
    relatedTags: [
      "Redux",
      "GraphQL",
      "D3",
      "Tailwind",
      "Jest",
      "Bootstrap",
      "SCSS",
      "Canvas",
      "Node.js",
      "Prisma",
    ],
    active: false,
    relatedWork: [
      "Harmony",
      "The Night Sky",
      "Press Customer App",
      "Press Provider App",
      "Visualizing Log Volume",
      "Press Customer",
      "Press Partner",
      "Print on Demand Editor",
    ],
  },
  {
    name: "React Native",
    relatedTags: ["Redux", "Google Maps API"],
    active: false,
    relatedWork: ["Press Driver"],
  },
  {
    name: "Vue",
    relatedTags: ["Vuex", "WebRTC", "SCSS", "Jest"],
    active: false,
    relatedWork: ["FRQNCY", "Live Streaming TedX"],
  },
  {
    name: "Angular",
    relatedTags: ["MaterialUI"],
    active: false,
    relatedWork: ["Work Machines", "RHC Consulting", "Work Machines MVP"],
  },
  {
    name: "Gatsby",
    relatedTags: ["SCSS", "D3", "Google Analytics"],
    active: false,
    relatedWork: ["RHC Marketing"],
  },
  {
    name: "Vuex",
    relatedTags: ["Vue", "WebRTC", "SCSS", "Jest"],
    active: false,
    relatedWork: ["FRQNCY", "Live Streaming TedX"],
  },
  {
    name: "Redux",
    relatedTags: [
      "React",
      "Bootstrap",
      "React Native",
      "Google Maps API",
      "SCSS",
      "Canvas",
    ],
    active: false,
    relatedWork: [
      "The Night Sky",
      "Press Customer App",
      "Press Provider App",
      "Press Customer",
      "Press Partner",
      "Press Driver",
      "Print on Demand Editor",
    ],
  },
  {
    name: "GraphQL",
    relatedTags: ["D3", "React", "Tailwind", "Jest", "Node.js", "Prisma"],
    active: false,
    relatedWork: ["Harmony", "Visualizing Log Volume"],
  },
  {
    name: "Jest",
    relatedTags: [
      "React",
      "GraphQL",
      "Tailwind",
      "D3",
      "Vue",
      "Vuex",
      "SCSS",
      "WebRTC",
      "Node.js",
      "Prisma",
    ],
    active: false,
    relatedWork: ["Visualizing Log Volume", "Live Streaming TedX"],
  },
  {
    name: "Node.js",
    relatedTags: ["React", "GraphQL", "Tailwind", "D3", "Jest", "Prisma"],
    active: false,
    relatedWork: ["Visualizing Log Volume"],
  },
  {
    name: "Prisma",
    relatedTags: ["React", "GraphQL", "Tailwind", "D3", "Jest", "Node.js"],
    active: false,
    relatedWork: ["Visualizing Log Volume"],
  },
  {
    name: "Canvas",
    relatedTags: ["React", "Redux", "SCSS"],
    active: false,
    relatedWork: ["Print on Demand Editor"],
  },
  {
    name: "D3",
    relatedTags: [
      "React",
      "GraphQL",
      "Tailwind",
      "Jest",
      "Gatsby",
      "SCSS",
      "Google Analytics",
      "Node.js",
      "Prisma",
    ],
    active: false,
    relatedWork: ["Harmony", "Visualizing Log Volume", "RHC Marketing"],
  },
  {
    name: "WebRTC",
    relatedTags: ["Vue", "Vuex", "GraphQL", "SCSS", "Jest"],
    active: false,
    relatedWork: ["FRQNCY", "Live Streaming TedX"],
  },
  {
    name: "Google Maps API",
    relatedTags: ["React Native", "Redux"],
    active: false,
    relatedWork: ["Press Driver"],
  },
  {
    name: "Google Analytics",
    relatedTags: ["Gatsby", "SCSS", "D3"],
    active: false,
    relatedWork: ["RHC Marketing"],
  },
  {
    name: "SCSS",
    relatedTags: [
      "Vue",
      "Vuex",
      "Jest",
      "WebRTC",
      "React",
      "Redux",
      "Canvas",
      "Gatsby",
      "D3",
      "Google Analytics",
    ],
    active: false,
    relatedWork: [
      "Live Streaming TedX",
      "Print on Demand Editor",
      "RHC Marketing",
    ],
  },
  {
    name: "Tailwind",
    relatedTags: ["React", "GraphQL", "D3", "Jest", "Node.js", "Prisma"],
    active: false,
    relatedWork: ["Visualizing Log Volume"],
  },
  {
    name: "Bootstrap",
    relatedTags: ["React", "Redux"],
    active: false,
    relatedWork: ["Press Customer", "Press Partner"],
  },
  {
    name: "MaterialUI",
    relatedTags: ["Angular"],
    active: false,
    relatedWork: ["Work Machines MVP"],
  },
];
export default tags;
