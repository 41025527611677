import React, { Fragment, useEffect } from "react";
import { useMediaPredicate } from "react-media-hook";

import { Link } from '@material-ui/core'
import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'

// Styles
import "./styles.scss";

// Components
// import AnimatedBlob from "../AnimatedBlob/AnimatedBlob";

//data
import bio from "../../../data/bio";
import logComputedTagsData from "../../../utils/logComputedTagsData";

const Bio = () => {
  // useEffect(() => {
  //   const modelViewer = document.querySelector("#model-viewer");
  //   const orbitCycle = [
  //     "0deg 1cm 100%",
  //     "-2deg 1cm 109%",
  //     "-8deg  1cm 109%",
  //     "-4deg 1cm 109%",
  //     "-2deg  1cm 109%",
  //     modelViewer.cameraOrbit,
  //   ];

  //   setInterval(() => {
  //     const currentOrbitIndex = orbitCycle.indexOf(modelViewer.cameraOrbit);
  //     modelViewer.cameraOrbit =
  //       orbitCycle[(currentOrbitIndex + 1) % orbitCycle.length];
  //   }, 800);
  // }, []);
  // useEffect(() => {
  //  logComputedTagsData();
  // }, []);

  return (
    <Fragment>
      {/* Container for bio */}
      <div className="root-bio">
        <div className="text">
          <span className="name">{bio.name}</span>
          <p>{bio.bio}</p>
          <div className="bio-btn-wrapper">
            {/* DOWNLOAD RESUME */}
            <a
              className="btn-resume-download"
              href="resume.pdf"
              download="JohnRamsey_Resume.pdf"
            >
              <span>Download Resume</span>
              <img src="download_icon.svg"></img>
            </a>

            
            <a href="https://linkedin.com/in/john-ramsey-dev-design" target="_blank" style={{ all: 'unset', cursor: 'pointer' }}>
              <LinkedInIcon className="btn-linkedin" style={{ fontSize: 33 }} />
            </a>
            <a href="https://github.com/johnsephr" target="_blank" style={{ all: 'unset', cursor: 'pointer' }}>
              <GitHubIcon className="btn-github" style={{ fontSize: 29 }} />
            </a>
          </div>
        </div>
        <div className="model-viewer-container">
          {/* <AnimatedBlob />
          <model-viewer
            id="model-viewer"
            style={{
              outline: "none",
              border: "none",
              boxShadow: "none",
              outlineWidth: "0px",
              zindex: "999",
            }}
            src="/dex-bust.glb"
            ios-src="/dex-bust.glb"
            alt="A 3D model of my bio."
            shadow-intensity="0"
            disable-zoom
            interpolation-decay="500"
          ></model-viewer> */}
          <img src="./bw.jpg" ></img>
        </div>
      </div>
    </Fragment>
  );
};

export default Bio;
