import React from "react";

// Components
import BioContainer from "../BioContainer/BioContainer";
import WorkContainer from "../WorkContainer/WorkContainer";
import ListFilter from "../../components/Custom/ListFilter/ListFilter";
import FixedListFilter from "../../components/Custom/ListFilter/FixedListFilter";

// Context
import { TagsProvider } from "../../contexts/TagsContext";


// Styles
import "./styles.scss";

const HomePage = () => {
  return (
    <div className="home-page-root">
      <div className="home-page-wrapper">
        <BioContainer />
        {/* Context Declared*/}
        <TagsProvider>
          <ListFilter />
          <FixedListFilter />
          <WorkContainer />
        </TagsProvider>
      </div>
    </div>
  );
};

export default HomePage;

// style={biggerThan2200 ? { maxWidth: 1400 } : biggerThan1450 ? { margin: '0 5%' } : biggerThan1250 ? { margin: '0 20%' } : biggerThan1000 ? { margin: '0 15%' } : { margin: '0 10%' }}
