import React, { useState, useEffect } from "react";
import { useMediaPredicate } from "react-media-hook";

// MUI
import { Typography, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// stylesheet
import "./styles.scss";

// React Modal
import ReactModal from "react-modal";

// images
import harmonyMobile from "../../../images/harmony-mobile.png";
import harmonyWeb from "../../../images/harmony-web.png";
import tnsWeb from "../../../images/tns-web.png";
import tnsMobile from "../../../images/tns-mobile.png";
import workMachinesWeb from "../../../images/work-machines-web.png";
import workMachinesMobile from "../../../images/work-machines-mobile.png";
import pressCustomerWeb from "../../../images/press-customer-web.png";
import pressCustomerMobile from "../../../images/press-customer-mobile.png";
import pressProviderWeb from "../../../images/press-provider-web.png";
import pressProviderMobile from "../../../images/press-provider-mobile.png";

import pressDriverMobile from "../../../images/press-driver-mobile.png";
import pressDriverMobile2 from "../../../images/press-driver-mobile-2.png";
import pressDriverMobile3 from "../../../images/press-driver-mobile-3.png";

import rhcConsultingWeb from "../../../images/rhc-consulting-web.jpg";
import rhcConsultingMobile from "../../../images/rhc-consulting-mobile.png";
import calculatorWeb from "../../../images/calc-web.png";
import calculatorMobile from "../../../images/calc-mobile.png";
import awesomeJobsWeb from "../../../images/awesome-jobs-web.png";
import awesomeJobsMobile from "../../../images/awesome-jobs-mobile.png";
import ecommerceWeb from "../../../images/ecommerce-web.png";
import ecommerceMobile from "../../../images/ecommerce-mobile.png";
import frqncyWeb from "../../../images/frqncy-web.png";
import frqncyMobile from "../../../images/frqncy-mobile.png";

// videos
import harmonyWebVideo from "../../../videos/harmony-web.mp4";
import providerWebVideo from "../../../videos/provider-web.mp4";
import customerWebVideo from "../../../videos/customer-web.mp4";
import tnsCreateVideo from "../../../videos/tns-create.mp4";
import workMachinesVideo from "../../../videos/work-machines.mp4";
import frqncyWebVideo from "../../../videos/frqncy-web.mp4";

// Styles
const useStyles = makeStyles((theme) => ({
  // styles go here
  root: {
    margin: "0 0 350px 0",
  },
  text: {
    flexGrow: 1,
  },
  projectContainer: {
    display: "flex",
    justifyContent: "center",
    justifyContent: "space-between",
  },
  project: {},
  imagesContainer: {
    display: "inline-block",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  image: {
    borderRadius: "3px",
    boxShadow: "0 0 30px rgba(40,45,35, .1)",
  },
  infoContainer: {
    maxWidth: "45%",
    paddingLeft: "7%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    margin: "5px 0",
  },
  roles: {
    margin: "5px 0",
    color: "#282D23",
  },
  summary: {
    margin: "5px 0",
  },
  buttons: {
    width: "100%",
    paddingTop: 40,
  },
  buttonR: {
    marginRight: 20,
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.6)",
      backgroundColor: "transparent",
    },
  },
  buttonL: {
    float: "right",
    marginLeft: 20,
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.6)",
      backgroundColor: "transparent",
    },
  },
}));

const Project = (props) => {
  const classes = useStyles(props);

  // destructure
  const { project } = props;
  const { title, summary, image, tags } = project;

  // media queries
  const biggerThan1500 = useMediaPredicate("(min-width: 1500px)");
  const smallerThan1450 = useMediaPredicate("(max-width: 1450px)");

  // media state
  const [video, setVideo] = useState(null);
  const [webImage, setWebImage] = useState(null);
  const [mobileImage, setMobileImage] = useState(null);
  const [mobileImage2, setMobileImage2] = useState(null);
  const [mobileImage3, setMobileImage3] = useState(null);

  // functional state
  const [modalOpen, setModalOpen] = useState(false);

  // Component Did Mount
  useEffect(() => {
    switch (image) {
      case "frqncy":
        setWebImage(frqncyWeb);
        setMobileImage(frqncyMobile);
        setVideo(frqncyWebVideo);
        break;
      case "harmony":
        setWebImage(harmonyWeb);
        setMobileImage(harmonyMobile);
        setVideo(harmonyWebVideo);
        break;
      case "tns":
        setVideo(tnsCreateVideo);
        setWebImage(tnsWeb);
        setMobileImage(tnsMobile);
        break;
      case "work-machines":
        setVideo(workMachinesVideo);
        setWebImage(workMachinesWeb);
        setMobileImage(workMachinesMobile);
        break;
      case "press-customer-app":
        setWebImage(pressCustomerWeb);
        setMobileImage(pressCustomerMobile);
        setVideo(customerWebVideo);
        break;
      case "press-provider-app":
        setWebImage(pressProviderWeb);
        setMobileImage(pressProviderMobile);
        setVideo(providerWebVideo);
        break;
      case "press-driver-app":
        setMobileImage(pressDriverMobile);
        setMobileImage2(pressDriverMobile2);
        setMobileImage3(pressDriverMobile3);
        break;
      case "rhc-consulting":
        setWebImage(rhcConsultingWeb);
        setMobileImage(rhcConsultingMobile);
        break;
      case "calculator":
        setWebImage(calculatorWeb);
        setMobileImage(calculatorMobile);
        break;
      case "awesome-jobs":
        setWebImage(awesomeJobsWeb);
        setMobileImage(awesomeJobsMobile);
        break;
      case "ecommerce":
        setWebImage(ecommerceWeb);
        setMobileImage(ecommerceMobile);
        break;
      default:
        break;
    }
  }, [image]);

  // Open Modal
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  return (
    <div className={classes.root}>
      <ReactModal
        isOpen={modalOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModalOpen(false)}
        style={{
          overlay: {
            display: "flex",
            justifyContent: "center",
          },
          content: {
            right: "unset",
            bottom: "unset",
            overflow: "hidden",
            maxHeight: 840,
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <video
          autoPlay
          height={biggerThan1500 ? "800px" : !smallerThan1450 ? "700px" : "0px"}
        >
          <source controls src={video} playsinline="true" preload="auto" />
        </video>
      </ReactModal>

      <div className={classes.projectContainer}>
        <div
          className={classes.project}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {project.image && project.title != "Press Driver" && (
            <div
              className={classes.imagesContainer}
              style={{ transform: "translateX(15px)" }}
            >
              <img
                src={webImage}
                width="100%"
                height="auto"
                alt=""
                className={classes.image}
                style={{ borderRadius: "5px" }}
              />
              <img
                src={mobileImage}
                width="20%"
                height="auto"
                alt=""
                className={classes.image}
                style={{
                  position: "absolute",
                  bottom: -10,
                  left: -15,
                  borderRadius: "5px",
                }}
              />
            </div>
          )}
          {project.image && project.title == "Press Driver" && (
            <div className={classes.imagesContainer}>
              <img
                src={pressDriverMobile}
                width="33%"
                height="auto"
                alt=""
                className={classes.image}
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: -15,
                  borderRadius: "15px",
                }}
              />
              <img
                src={pressDriverMobile2}
                width="33%"
                height="auto"
                alt=""
                className={classes.image}
                style={{
                  marginLeft: "33%",
                  transform: "translate(4px, 5px)",
                  borderRadius: "15px",
                }}
              />
              <img
                src={pressDriverMobile3}
                width="33%"
                height="auto"
                alt=""
                className={classes.image}
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: -15,
                  borderRadius: "15px",
                }}
              />
            </div>
          )}
        </div>
        <div
          className={classes.infoContainer}
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              marginLeft: "auto",
              margin: "auto 0",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              className={classes.title}
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {title}
            </Typography>

            {/* tags */}
            <div
              className="tags-container"
              style={tags ? { display: "flex" } : { display: "none" }}
            >
              {tags &&
                tags.map((tag) => {
                  return (
                    <div className="tag-wrapper">
                      <span className="tag-text">{tag}</span>
                    </div>
                  );
                })}
            </div>

            <Typography variant="body1" className={classes.summary}>
              {summary}
            </Typography>
            <div className="buttons">
              {project.website && (
                <button className="button">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                    href={project.website}
                  >
                    {project.company}
                  </a>
                </button>
              )}
              {project.video && (
                <button onClick={handleModalOpen} className="button">
                  <span className={classes.link}>View Demo</span>
                </button>
              )}
              {project.googlePlayStoreURL && (
                <button className="button">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                    href={project.googlePlayStoreURL}
                  >
                    Google Play Store
                  </a>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
