import React, { useRef, useEffect, Fragment } from "react";
import { useTags, useTagsDispatch } from "../../../contexts/TagsContext.js";

// Styles
import "./fixed_styles.scss";

const ListFilter = () => {
  const dispatch = useTagsDispatch();
  const tagsState = useTags();
  const stickyRef = useRef();
  const buttonWrapperRef = useRef();

  useEffect(() => {
    const stickyEl = document.querySelector(".sticky-wrapper");
    const fixedStickyEl = document.querySelector(".fixed-sticky-wrapper");
    const observer = new IntersectionObserver(
      ([e]) => {
        // e.target.classList.toggle("hide", e.intersectionRatio < 1);
        return fixedStickyEl.classList.toggle("show", e.intersectionRatio < 1);
      },
      { threshold: [1], rootMargin: "0px 0px 500px 0px" }
    );

    window.onscroll = () => {
      observer.observe(stickyEl);

      if (window.pageYOffset === 0) {
        dispatch({
          type: "reset_tags",
        });
      }
    };
  }, []);

  // handleScrollTrigger
  function handleScrollTrigger() {
    buttonWrapperRef.current.scrollTo(0, 0);
    window.scroll({
      behavior: "smooth",
      top:
        document.querySelector("#work-container").getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        100,
    });
  }

  return (
    <div ref={stickyRef} className="fixed-sticky-wrapper">
      <div ref={buttonWrapperRef} className="btn-wrapper shadow">
        <div className={
          tagsState.activeTags === null || tagsState.activeTags.length < 1
            ? "all-button-container container-active"
            : "all-button-container"
        }>
          {/* All Button Mobile */}
          <button
            onClick={() => {
              handleScrollTrigger();
              dispatch({
                type: "reset_tags",
              });
            }}
            className={`tag-btn all-btn ${tagsState.activeTags === null || tagsState.activeTags.length < 1
              ? "active"
              : ""
              }`}
          >
            <span>
              {tagsState.activeTags === null || tagsState.activeTags.length < 1
                ? "All Projects"
                : "X"
              }
            </span>
            <div className="svg-wrapper">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>

            </div>
          </button>

          {/* BLUR */}
          {/* <div className="blur-left" /> */}
        </div>
        <div className={
          tagsState.activeTags === null || tagsState.activeTags.length < 1
            ? "buttons-wrapper"
            : "buttons-wrapper container-active"
        }>
          {/* Active Tags */}
          {tagsState.activeTags !== null &&
            tagsState.activeTags.map((activeTag, index, array) => {
              return (
                <Fragment>
                  <button
                    key={index}
                    onClick={() => {
                      handleScrollTrigger();
                      dispatch({
                        type: "update_tags",
                        payload: activeTag,
                      });
                    }}
                    className={`tag-btn active ${activeTag.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}`}
                  >
                    {activeTag.name}
                  </button>
                  <span
                    className="plus-sign"
                    style={
                      !tagsState.relatedTags.length && index == array.length - 1
                        ? { display: "none" }
                        : { display: "inline-flex" }
                    }
                  >
                    +
                  </span>
                </Fragment>
              );
            })}
          {/* {tagsState.activeTags.length > 0 && <span>+</span>} */}
          {/* Tags/relatedTags */}
          {tagsState.tagsData.map((tag, index) => {
            if (
              tagsState.activeTags === null ||
              tagsState.activeTags.length < 1 ||
              tagsState.relatedTags.includes(tag)
            ) {
              return (
                <button
                  onClick={() => {
                    handleScrollTrigger();
                    dispatch({
                      type: "update_tags",
                      payload: tag,
                    });
                  }}
                  className={`tag-btn 
              ${tag.active ? "active" : ""} 
              ${tag.name.toLowerCase()}`}
                  key={index}
                >
                  {tag.name}
                </button>
              );
            } else return;
          })}
        </div>
      </div>
      <div className="blur-right" />
    </div>
  );
};

export default ListFilter;
