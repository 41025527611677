import React from "react";

// Components
import Bio from "../../components/Custom/Bio/Bio";

// SCSS
import "./styles.scss";

const BioContainer = (props) => {
  return (
    <div className="root-bio-container">
      <Bio />
    </div>
  );
};

export default BioContainer;
